@font-face {
    font-family: 'Angry';
    src: url('../../../fonts/angrybirds-regular.ttf') format('truetype'); /* Cambia según el tipo de archivo */
    font-weight: normal;
    font-style: normal;
  }
  
.pending-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #fff9e5; /* Fondo amarillo claro */
    color: #333;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }
  
  .pending-logo img {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .pending-title {
    font-size: 2rem;
    color: #fbc02d; /* Amarillo para indicar estado pendiente */
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif; /* Usa Roboto si ya está configurado */
    font-weight: 700; /* Negrita */
    font-family: 'Angry', sans-serif;
  }
  
  .pending-message {
    font-size: 1.2rem;
    color: #f9a825; /* Amarillo más oscuro */
    margin-bottom: 20px;
  }
  
  .pending-details ul {
    list-style: none;
    padding: 0;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .pending-details li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
  }
  
  .pending-details li strong {
    color: #f9a825; /* Amarillo más oscuro */
  }
  

  .deep-link-button {
    background-color: #d32f2f; /* Rojo para indicar error */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    font-family: 'Angry', sans-serif;
  }
  
  .deep-link-button:hover {
    background-color: #c62828;
  }

  .deep-link-button {
    background-color: #fbc02d; 
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    font-family: 'Roboto', sans-serif;
  }
  
  .deep-link-button:hover {
    background-color: #f9a825;
  }
  
  