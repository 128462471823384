@font-face {
    font-family: 'Angry';
    src: url('../../../fonts/angrybirds-regular.ttf') format('truetype'); /* Cambia según el tipo de archivo */
    font-weight: normal;
    font-style: normal;
  }
  
.failure-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #fff5f5; /* Fondo rojo claro */
    color: #333;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }
  
  
  .failure-logo img {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .failure-title {
    font-size: 2rem;
    color: #d32f2f; /* Rojo para indicar error */
    margin-bottom: 10px;
    font-family: 'Angry', sans-serif;
  }
  
  .failure-message {
    font-size: 1.2rem;
    color: #d32f2f; /* Rojo para resaltar el mensaje */
    margin-bottom: 20px;
  }
  
  .failure-details ul {
    list-style: none;
    padding: 0;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .failure-details li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
  }
  
  .failure-details li strong {
    color: #d32f2f; /* Rojo para resaltar los detalles */
  }
  

  .deep-link-button {
    background-color: #d32f2f; /* Rojo para indicar error */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    font-family: 'Angry', sans-serif;
  }
  
  .deep-link-button:hover {
    background-color: #c62828;
  }
  